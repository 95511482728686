import ru from './locales/ru'
import en from './locales/en.json'
import jp from './locales/jp'

const messages = {
  en: en,
  ru: ru,
  jp: jp
}

export default messages
